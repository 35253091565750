import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { useInView } from "react-intersection-observer";

import header from "../assets/Peeling/PeelingHeader.webp";
import imgInicio from "../assets/Peeling/PeelingTextCort.webp";
import imgLiftingCard from "../assets/Peeling/PeelingCardCort.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import Acordeao from "../components/layout/Acordeao";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";
import AgendarCita from "../components/Seção_Agendar/AgendarCita";

//Components

function Peeling() {
  const { ref, inView } = useInView({
    triggerOnce: false, // A animação será acionada apenas uma vez
    threshold: 0.1, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setAnimate(true);
      }, 100); // Ajuste o tempo se necessário
    } else {
      setAnimate(false);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Peeling01")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Peeling02")}
            subtitle={t("Peeling03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Peeling04")}
            subtitle={t("Peeling05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Peeling06")}
            subtitle={t("Peeling07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Peeling08")}
            subtitle={t("Peeling09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro2} ${animate ? styles.fadeIn : ""}`}
            alt={t("Peeling10")}
            ref={ref}
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${inView ? styles.slideLeft : ""}`}
          >
            {" "}
            {t("Peeling10")}
            <p className={styles.subtitleIntro}> {t("Peeling11")} </p>
          </h2>
          <p>{t("Peeling12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "300px"]}>
          <h1 className={styles.titlePrincipalContainer}> {t("Peeling13")} </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Peeling14")}
              content={[t("Peeling15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Peeling16")}
              content={[t("Peeling17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Peeling18")}
              content={[t("Peeling19")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>

      <div className={styles.containerAcordeao}>
        <h1> {t("Peeling20")} </h1>
        <div className={styles.contentAcordeao}>
          <Acordeao title={t("Peeling21")} content={t("Peeling22")} />

          <Acordeao title={t("Peeling23")} content={t("Peeling24")} />

          <Acordeao title={t("Peeling25")} content={t("Peeling26")} />
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div
            className={` ${styles.slideRight}`}
            id={styles.cardTratamiento}
            ref={ref}
          >
            <div className={styles.contentTratamiento}>
              <p className={styles.titlePrincipal}> {t("Peeling27")}</p>
              <p className={styles.conteudoTratamiento}>
                <span key="1">
                  <strong>{t("Peeling28")}</strong> {t("Peeling29")}
                </span>

                <br />
                <span key="2">
                  <strong> {t("Peeling30")} </strong> {t("Peeling31")}
                </span>

                <br />
                <span key="3">
                  <strong>{t("Peeling32")} </strong> {t("Peeling33")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Peeling34")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Peeling35")}</p>
                    <p>{t("Peeling36")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}> {t("Peeling37")} </p>
                    <p>{t("Peeling38")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Peeling39")}</p>
                    <p>{t("Peeling40")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.containerPlus}>
        <h4> {t("Peeling41")}</h4>
        <p className={styles.subtitlePlus}>{t("Peeling42")}</p>
        <div className={styles.contentPlus} id={styles.plusAlternativo}>
          <div className={styles.circlePlus}>
            <a href="/Mesoterapia"> {t("Peeling43")}</a>
          </div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <AgendarCita />
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Peeling44")} </h2>
          <Perguntas
            pergunta={t("Peeling45")}
            resposta={t("Peeling46")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Peeling47")}
            resposta={t("Peeling48")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Peeling49")}
            resposta={t("Peeling50")}
            eventKey="2"
          />
        </div>
      </div>
    </div>
  );
}

export default Peeling;
