import React from "react";
import { IoMdArrowForward } from "react-icons/io";
import styles from "../../pages/lifting.module.css";
import { useState } from "react";
function CardDuvidas({
  title,
  content,
  icon,
  className,
  isLarge,
  backgroundImage,
}) {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      className={`${styles.cardShort} ${className} ${
        isLarge ? styles.cardBig : ""
      }`}
    >
      <div
        className={`${styles.contentCard} ${flipped ? styles.flipFront : ""}`}
        id={styles.cardPrincipal}
        style={{
          backgroundImage: `linear-gradient(#36363675, #0a0d3863), url(${backgroundImage})`,
        }}
      >
        <p className={styles.titlePrincipal}>
          {title}
          {icon && <span className={styles.icon}>{icon}</span>}
        </p>
        <IoMdArrowForward className={styles.arrowIcon} />
      </div>
      <div className={`${styles.cardBack} ${flipped ? styles.flipBack : ""}`}>
        <div className={styles.content}>
          {content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardDuvidas;
