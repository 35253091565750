import { Button } from "react-bootstrap";
import "./ButtonBlack.css";

function ButtonBlack({ content, type }) {
  return (
    <Button type={type} className="buttonBlack">
      {content}
    </Button>
  );
}

export default ButtonBlack;
