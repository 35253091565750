import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import styles from "./Gift.module.css";

function Gift() {
  return (
    <div className={styles.container}>
      <a href="#">
        <div className={styles.content}>
          <div className={styles.giftContainer}>
            <DotLottieReact
              src="https://lottie.host/ef7cd5f7-ae04-4fe6-a9ad-65bf1e17a444/ZhUTciEUNm.lottie"
              loop
              autoplay
              className={styles.gift}
              
            />
          </div>
          <div className={styles.contentText}>
            <p className={styles.text}>Click on Me!</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default Gift;