import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect, useRef } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { IoMdArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import header from "../assets/LaserCO2/LaserCO2Header.webp";
import imgInicio from "../assets/LaserCO2/LaserCO2TExt1.webp";
import imgLiftingCard from "../assets/LaserCO2/LaserCO2TExt3.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import { FaRegFaceDizzy } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import CardSwiperMobile from "../components/layout/cardSwiperMobile";
import { FaCheck } from "react-icons/fa6";

//Componets
import AgendarCita from "../components/Seção_Agendar/AgendarCita";
//Translation
import { useTranslation } from "react-i18next";
import FloatButton from "../components/FloatButton";
//Components
import CustomSlider from "../components/layout/CustomSlider";
function LaserCO2() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.1, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);
  const [currentSlide, setCurrentSlide] = useState(0); // Estado para controlar o slide atual

  // Função para atualizar o slide atual
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  // Corrigido para garantir que a referência ao Swiper exista antes de chamar métodos sobre ela
  const goToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };
  //Translation
  const { t } = useTranslation();

  const swiperRef = useRef(null);
  const slides = [
    {
      title: [t("Laser39")],
      img: imgInicio,
      titleList: [t("Laser40"), t("Laser42")],
      contentList: [t("Laser41"), t("Laser43")],
      textColor: "black", // Texto preto para o primeiro slide
    },
    {
      title: [t("Laser44")],
      img: imgInicio,
      titleList: [t("Laser45"), t("Laser47")],
      contentList: [t("Laser46"), t("Laser48")],
      textColor: "black", // Texto preto para o primeiro slide
    },
    {
      title: [t("Laser49")],
      img: imgInicio,
      titleList: [t("Laser50"), t("Laser52")],
      contentList: [t("Laser51"), t("Laser53")],
      textColor: "black", // Texto branco para o segundo slide
    },

    {
      title: [t("Laser54")],
      img: imgInicio,
      titleList: [t("Laser55"), t("Laser57")],
      contentList: [t("Laser56"), t("LaserBeneficios59")],
      textColor: "black", // Texto branco para o segundo slide
    },

    // Adicione mais slides conforme necessário
  ];

  return (
    <div>
      <Header
        Titulo={t("LaserCO2")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Laser01")}
            subtitle={t("Laser02")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Laser03")}
            subtitle={t("Laser04")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Laser05")}
            subtitle={t("Laser06")}
          />
          <Icon
            src={iconDuracion}
            title={t("Laser07")}
            subtitle={t("Laser08")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedirCita1Boton")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Laser09")}
          </h2>
          <p>{t("Laser10")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}> {t("Laser11")} </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Laser12")}
              content={[t("Laser13")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Laser14")}
              content={[t("Laser15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />

            <CardDuvidas
              title={t("Laser16")}
              content={[t("Laser17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div
            className={`${styles.cardShort} ${styles.slideRight}`}
            id={styles.cardTratamiento}
          >
            <div className={styles.contentCard}>
              <p className={styles.titlePrincipal} isLarge={true}>
                {t("Laser18")}
              </p>
              <p className={`${styles.conteudo} ${styles.conteudoAdd}`}>
                <span key="1" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}> </strong>
                  {t("Laser19")}
                </span>
                <br />
                <span key="2" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}></strong>{" "}
                  {t("Laser20")}
                </span>
                <br />
                <span key="3" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}></strong>
                  {t("Laser21")}
                </span>
                <br />
                <span key="4" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}></strong>{" "}
                  {t("Laser22")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Laser23")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Laser24")}</p>
                    <p>{t("Laser25")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Laser26")}</p>
                    <p>{t("Laser27")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Laser28")}</p>
                    <p> {t("Laser29")} </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.swiperContainer} id={styles.swiperAjuste}>
        <CustomSlider
          slides={slides}
          showTitleCard={true}
          titleCard={t("Laser59")}
        />
      </div>

      <div className={styles.containerPlus}>
        <h4> {t("Laser30")} </h4>

        <div>
          <Button
            className={styles.button}
            id={styles.buttonPlus}
            href="\Servicios"
          >
            {t("Laser31")}
          </Button>
        </div>
      </div>
      <AgendarCita />
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Laser32")} </h2>
          <Perguntas
            pergunta={t("Laser33")}
            resposta={t("Laser34")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Laser35")}
            resposta={t("Laser36")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Laser37")}
            resposta={t("Laser38")}
            eventKey="2"
          />
        </div>
      </div>
    </div>
  );
}

export default LaserCO2;
