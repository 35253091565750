// DropdownLang.js
import React, { useEffect, useState } from "react";
import i18n, { langs } from "../../locales/i18.mjs";
import "./dropdown-lang.css";

const DropdownLang = ({ scrolled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    langs.find((l) => l.value === "es")
  );

  useEffect(() => {
    const storedLang = window.localStorage.getItem("lang");
    if (storedLang) {
      i18n.changeLanguage(storedLang);
      setCurrentLang(langs.find((l) => l.value === storedLang));
    }
  }, []);

  const changeLang = (lang) => {
    window.localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    setIsOpen(false);
    setCurrentLang(langs.find((l) => l.value === lang));
  };

  return (
    <div className="content">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`content-lang ${scrolled ? "scrolled" : ""}`}
      >
        <img className="img-lang" src={currentLang.icon} alt="" />
        <span className={`lang ${scrolled ? "scrolled" : ""}`}>
          {currentLang.label}
        </span>
      </button>
      {isOpen && (
        <div className="other-lang">
          <ul className="list-lang">
            {langs.map((lang) => (
              <li
                key={lang.value}
                onClick={() => changeLang(lang.value)}
                className="item-list-lang"
              >
                <img className="" src={lang.icon} alt="" />
                <span>{lang.label}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownLang;
