import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { useInView } from "react-intersection-observer";

import header from "../assets/RellenoMenton/RellenoMentonHeader5.webp";
import imgInicio from "../assets/RellenoMenton/RellenoMentonText.Cort.webp";
import imgLiftingCard from "../assets/RellenoMenton/RellenoMentonText1.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";
import AgendarCita from "../components/Seção_Agendar/AgendarCita";

//Components

function RellenodeMenton() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Menton01")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Menton02")}
            subtitle={t("Menton03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Menton04")}
            subtitle={t("Menton05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Menton06")}
            subtitle={t("Menton07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Menton08")}
            subtitle={t("Menton09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? ` ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Menton10")}
            <p
              className={`${styles.subtitleIntro} ${
                animate ? ` ${styles.slideLeft}` : ""
              }`}
              ref={ref}
            >
              {t("Menton11")}
            </p>
          </h2>

          <p>{t("Menton12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "300px"]}>
          <h1 className={styles.titlePrincipalContainer}> {t("Menton13")} </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Menton14")}
              content={[t("Menton15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Menton16")}
              content={[t("Menton17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Menton18")}
              content={[t("Menton19")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div
            className={`${styles.cardShort} ${styles.slideRight}`}
            id={styles.cardTratamiento}
          >
            <div className={styles.contentCard}>
              <p className={styles.titlePrincipal}> {t("Menton20")} </p>

              <p className={styles.conteudo}>
                <span key="1" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Menton21")}{" "}
                  </strong>{" "}
                  {t("Menton22")}
                </span>
                <br />
                <span key="2" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Menton23")}
                  </strong>{" "}
                  {t("Menton24")}
                </span>
                <br />
                <span key="3" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Menton25")}
                  </strong>{" "}
                  {t("Menton26")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Menton27")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Menton28")}</p>
                    <p>{t("Menton29")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}> {t("Menton30")} </p>
                    <p>{t("Menton31")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Menton32")}</p>
                    <p>{t("Menton33")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4> {t("Menton34")} </h4>
        <div className={styles.contentPlus}>
          <div className={styles.circlePlus}>
            <a href="/PerfiladoMandibular"> {t("Menton35")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="RellenodeLabios"> {t("Menton36")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="Rinomodelacion"> {t("Menton37")} </a>
          </div>
        </div>
        <div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {" "}
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <AgendarCita />
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Menton38")} </h2>
          <Perguntas
            pergunta={t("Menton39")}
            resposta={t("Menton40")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Menton41")}
            resposta={t("Menton42")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Menton43")}
            resposta={t("Menton44")}
            eventKey="2"
          />
        </div>
      </div>
    </div>
  );
}

export default RellenodeMenton;
