import React, { useState, useEffect } from "react";
import styles from "./CustomSlider.module.css"; // Asumindo que você criará um arquivo CSS separado
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CustomSlider = ({ slides, showTitleCard = true, titleCard }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000); // Muda o slide a cada 10 segundos
    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className={styles.container}>
      {" "}
      {showTitleCard && (
        <div className={styles.titleCard}>
          <h3>{titleCard}</h3>
          {slides.map((item, index) => (
            <h4
              key={index}
              className={
                index === currentIndex ? styles.active : styles.inactive
              }
              onClick={() => goToSlide(index)}
            >
              {item.title}
            </h4>
          ))}
        </div>
      )}
      <div className={styles.sliderContainer}>
        <div
          className={styles.sliderContent}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div className={styles.slide} key={index}>
              <img
                src={slide.img}
                alt={`Slide ${index}`}
                className={styles.slideImage}
              />
              <div
                className={styles.slideText}
                style={{ color: slide.textColor }}
              >
                <h3>{slide.title}</h3>
                <ul>
                  {slide.titleList.map((title, idx) => (
                    <li key={idx}>
                      <h4>{title}</h4>
                      <p>{slide.contentList[idx]}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={prevSlide}
          className={`${styles.button} ${styles.prevButton}`}
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={nextSlide}
          className={`${styles.button} ${styles.nextButton}`}
        >
          <IoIosArrowForward />
        </button>
        <div className={styles.pagination}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={`${styles.dot} ${
                index === currentIndex ? styles.active : ""
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
