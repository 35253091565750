import { useInView } from "react-intersection-observer";

import img01 from "../../Data/image_carrossel_gota.png";
import img02 from "../../assets/carousel/carrossel_seringa.png";
import img03 from "../../assets/carousel/carrossel_manos.png";
import img04 from "../../assets/carousel/frasco_2.png";

import styles from "./HomeStyles.module.css";
import seta from "../../assets/seta.svg";

//Images
import imgAgenda from "../../assets/images_home/HomeAgendar2.webp";

//icons
import iconProInova from "../../assets/icons/Innovadores01.webp";
import iconExpCapacitacao from "../../assets/icons/Capacitacion01.webp";
import iconSegConfianca from "../../assets/icons/Confianza01.webp";
import iconResultNatural from "../../assets/icons/Naturales01.webp";

//Translation
import { useTranslation } from "react-i18next";

import React, {
  startTransition,
  useEffect,
  useState,
  Suspense,
  lazy,
} from "react";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import FloatButton from "../../components/FloatButton.js";

import { Accordion } from "react-bootstrap";

import ButtonWhite from "../../components/layout/ButtonWhite.js";
import ButtonGreen from "../../components/layout/ButtonGreen.js";
import ButtonBlack from "../../components/layout/ButtonBlack.js";
import Loading from "../../components/Loading/Loading.js";
import PopUp from "../../components/PopUp/PopUp.js";
import Gift from "../../components/animations/Gift.js";

// Lazy loaded components

const Collapse = lazy(() => import("../../components/layout/Collapse"));

const VerticalCarousel = lazy(() =>
  import("../../components/VerticalSlider.js")
);
const ParallaxText = lazy(() => import("../../components/fraseVelocity.js"));
const Perguntas = lazy(() => import("../../components/Perguntas.js"));

const CarouselBlog = lazy(() =>
  import("../../components/layout/CarouselBlog.js")
);
function Home() {
  //Loading

  const [expanded, setExpanded] = useState(); // Estado para controlar a visibilidade da imagem
  const [isLoading, setIsLoading] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [animate, setAnimate] = useState(false);

  //PopUp
  const [modalVisible, setModalVisible] = useState(false);

  // Função para fechar o modal
  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    startTransition(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    });
  }, []);

  useEffect(() => {
    setAnimate(inView);
  }, [inView]);

  const [refImg, inViewImg] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [animateImg, setAnimateImg] = useState(false);

  useEffect(() => {
    if (inViewImg) {
      setAnimateImg(true);
      setTimeout(() => setShowImage(true), 2000);
    }
  }, [inViewImg]);

  useEffect(() => {
    if (videoLoaded) {
      setIsLoading(false);
    }
  }, [videoLoaded]);

  const handleVideoLoaded = () => {
    startTransition(() => {
      setVideoLoaded(true);
    });
  };
  //Translation
  const { t } = useTranslation();

  const slides = [
    {
      introline: t("Home33"),
      id: "1",
      descricao: {
        titleList: [t("Home34"), t("Home36"), t("Home38"), t("Home40")],
        description: [t("Home35"), t("Home37"), t("Home39"), t("Home41")],
      },

      content: {
        image: img01,
      },
    },
    {
      introline: t("Home42"),
      id: "2",
      descricao: {
        titleList: [t("Home43"), t("Home44"), t("Home45"), t("Home46")],
        description: [t("Home66"), t("Home67"), t("Home68"), t("Home69")],
      },
      content: {
        image: img02,
      },
    },
    {
      introline: t("Home47"),
      id: "3",
      descricao: {
        titleList: [t("Home48"), t("Home50")],
        description: [t("Home49"), t("Home51")],
      },
      content: {
        image: img03,
      },
    },
    {
      introline: t("Home52"),
      id: "4",
      descricao: {
        titleList: [t("headerHIFU"), t("Home55")],
        description: [t("Home54"), t("Home56")],
      },
      content: {
        image: img04,
      },
    },
    {
      introline: t("Home57"),
      id: "5",
      descricao: {
        titleList: [t("Home58"), t("Home60"), t("Home62"), t("Home72")],
        description: [t("Home70"), t("Home71"), t("Home63"), t("Home73")],
      },
      content: {
        image: img01,
      },
    },
  ];

  const items = [
    {
      title: t("Home24"),
      content: t("Home25"),
      num: "01",
    },
    {
      title: t("Home26"),
      content: t("Home27"),

      num: "02",
    },
    {
      title: t("Home28"),
      content: t("Home29"),

      num: "03",
    },
    {
      title: t("Home30"),
      content: t("Home31"),
      num: "04",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Rolagem para o topo da página
  }, []);

  const handleToggle = (eventKey) => {
    setExpanded(eventKey !== expanded); // Toggle state based on clicked item
  };

  useEffect(() => {
    // Atualiza o estado baseado na largura da tela
    const updateSize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateSize);
    updateSize(); // Chama na montagem para obter a largura inicial

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <div className={styles.containerHome}>
      <Suspense fallback={<Loading isLoading={true} />}>
        <div className={styles.containerVideo}>
          <video style={styles.video} controls autoPlay muted>
            <source
              src="https://storage.googleapis.com/arquivos-videos/videoIntro.mp4"
              type="video/mp4"
            />
            Seu navegador não suporta o elemento de vídeo.
          </video>

          <img src={seta} className={styles.seta} alt="Seta" />
          <PopUp isVisible={modalVisible} onHide={handleModalClose} />
        </div>
        <FloatButton mobileScrollPoint={4700} desktopScrollPoint={3000} />
        <div className={styles.sectionTexto}>
          <p className={`${styles.texto} ${styles.rotateIn}`}>
            {t("textIntro")}
          </p>
          <ParallaxProvider>
            <Parallax speed={50} translateY={["80px", "300px"]}>
              <div className={styles.circleBlack}></div>
            </Parallax>
            <Parallax speed={50} translateY={["80px", "-300px"]}>
              <div className={styles.circleEmpty}></div>
            </Parallax>
          </ParallaxProvider>
        </div>
        <div>{/* <Gift /> */}</div>
        <div className={styles.containerInfo}>
          <div className={styles.containerImgTexto}>
            <div className={styles.containerImg}>
              <div
                alt="Perfil"
                className={`${styles.infoImg} ${
                  animateImg && screenWidth >= 768 ? styles.slideRight : ""
                }`}
                style={{ display: isLoading ? "none" : "block" }}
              ></div>
            </div>
            <div className={styles.containerConteudo}>
              <h1
                className={`${styles.subtitleInfo} ${
                  animateImg ? ` ${styles.slideLeft}` : ""
                }`}
                ref={refImg}
              >
                {t("Home4")}
              </h1>
              <h4
                className={`${styles.titleInfo} ${
                  animateImg ? ` ${styles.slideLeft}` : ""
                }`}
                ref={refImg}
              >
                {" "}
                {t("subtitleInfo")}
              </h4>

              <p className={styles.conteudo}>{t("conteudoHome01")}</p>
              <p className={styles.conteudo}>{t("conteudoHome02")}</p>
              <p className={styles.conteudo}>{t("conteudoHome03")}</p>

              <p className={styles.assinatura}> {t("Home32")} </p>
            </div>
          </div>
        </div>
        <div className={styles.containerIcons}>
          <div className={styles.icons01}>
            <div className={styles.icons02}>
              <img src={iconProInova} className={styles.icon} />
              <p className={styles.iconTexto}>{t("icon01")}</p>
            </div>

            <div div className={styles.icons02}>
              <img src={iconExpCapacitacao} className={styles.icon} />

              <p className={styles.iconTexto}>{t("Home05")}</p>
            </div>
          </div>
          <div className={styles.icons01}>
            <div className={styles.icons02}>
              <img src={iconSegConfianca} className={styles.icon} />
              <p className={styles.iconTexto}>{t("Home06")}</p>
            </div>

            <div div className={styles.icons02}>
              <img src={iconResultNatural} className={styles.icon} />
              <p className={styles.iconTexto}> {t("Home07")} </p>
            </div>
          </div>
        </div>
        <div className={styles.containerImgHome}>
          <div className={styles.containerFrase}>
            <p className={styles.frase}>{t("Home08")}</p>
          </div>
          <div
            className={`${styles.imgHome} ${
              animate ? ` ${styles.zoomIn}` : ""
            }`}
            ref={ref}
          />
        </div>
        <div className={styles.containerServicios}>
          <VerticalCarousel data={slides} />
          <div className={styles.buttonCarousel}>
            <a href="/servicios" target="_blank">
              <ButtonBlack content={t("linkTratamientos")} />
            </a>
          </div>
        </div>
        <div className={styles.consultas}>
          <Collapse items={items} />
        </div>
        <div className={styles.containerAgenda}>
          <div className={styles.cardAgenda}>
            <div className={styles.contentAgenda}>
              <h1 className={styles.titleAgenda}> {t("agendaLifting")} </h1>
              <h1> {t("tuCitaAhoraLifting")} </h1>
            </div>
            <a href="https://wa.me/34651551473" target="_blank">
              <ButtonWhite content={t("pedircitaLifting")} />
            </a>
          </div>
          <img src={imgAgenda} className={styles.imgAgenda} />
        </div>
        <div className={styles.containerCarousel}>
          <div className={styles.containerBlog}>
            <div className={styles.contentBlog}>
              <h1 className={styles.titleBlog}> {t("Home10")} </h1>
              <h3 className={styles.descricaoBlog}>{t("Home11")}</h3>
              <p className={styles.subtitleBlog}>{t("Home12")}</p>
              <div className={styles.buttonBlog}>
                <a href="/blog" target="_blank">
                  <ButtonGreen content={t("Home13")} />
                </a>
              </div>
            </div>
            <div className={styles.carouselBlog}>
              <CarouselBlog />
            </div>
          </div>
        </div>
        <div className={styles.sectionComentarios}>
          <h2 className={styles.titleComentarios}> {t("Home14")} </h2>
          <div className={styles.perguntas}>
            <Accordion defaultActiveKey="0">
              <Perguntas
                pergunta={t("Home15")}
                resposta={t("Home16")}
                eventKey="0"
              />
              <Perguntas
                pergunta={t("Home17")}
                resposta={t("Home18")}
                eventKey="3"
              />
              <Perguntas
                pergunta={t("Home19")}
                resposta={t("Home20")}
                eventKey="4"
              />
              <Perguntas
                pergunta={t("Home21")}
                resposta={t("Home22")}
                eventKey="5"
              />
            </Accordion>
          </div>
        </div>
        <ParallaxText baseVelocity={-3}>
          {t("Parallax01")} {t("Parallax02")} {t("Parallax03")}
          {t("Parallax04")} {t("Parallax05")} {t("Parallax06")}
        </ParallaxText>
      </Suspense>
    </div>
  );
}

export default Home;
