import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Posts.module.css";
import useDataBlog from "../../../../Data/posts";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { RiShareFill } from "react-icons/ri";
import { WhatsappShareButton, MailruShareButton } from "react-share";
import { useTranslation } from "react-i18next";

function PostBlog() {
  const navigate = useNavigate();
  const { postId } = useParams(); // Pega o ID do post da URL
  const dataBlog = useDataBlog(); // Chamada correta da função
  const { t } = useTranslation();

  if (!dataBlog) {
    return <p>Carregando conteúdo do blog...</p>;
  }

  // Encontra o post correspondente pelo ID
  const post = dataBlog.find((item) => item._id === postId);
  if (!post) {
    return <p>Post não encontrado</p>; // Mensagem se o post não for encontrado
  }

  const formatContent = (contentArray) => {
    return (contentArray || []).map((content, index) => (
      <React.Fragment key={index}>
        {content.split("\n").map((paragraph, i) => (
          <p key={i}>{paragraph}</p>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <div className={styles.content}>
      <div className={styles.introPost}>
        <h1 className={styles.titleBlog}>{post.titleKey}</h1>
        <h2 className={styles.descricao}>{post.contentKeys}</h2>
        {post.photo && (
          <img
            src={post.photo}
            alt={post.title}
            className={`${styles.postBlog} ${
              postId === "3" ? styles.specialPostBlog : ""
            }
            ${postId === "10" ? styles.specialPostLaserlipolise : ""}
                ${postId === "4" ? styles.PostBlogGota : ""}${
              postId === "7" ? styles.specialPostLaserlipolise : ""
            }`}
          />
        )}
      </div>

      <div className={styles.sections}>
        {post.sections && post.sections.length > 0 ? ( // Verifica se existem seções
          post.sections.map((section, index) => (
            <div key={index} className={styles.section}>
              {section.titleKey && (
                <h2 className={styles.sectionTitle}>{section.titleKey}</h2>
              )}
              {section.subtitleKey && (
                <h3 className={styles.subtitleSection}>
                  {section.subtitleKey}
                </h3>
              )}
              {section.image && (
                <img
                  src={section.image}
                  alt={section.title}
                  className={styles.postBlogSection}
                />
              )}
              {section.contentKeys && section.contentKeys.length > 0 ? (
                section.contentKeys.map((content, contentIndex) => (
                  <div key={contentIndex} className={styles.contents}>
                    {formatContent([content])}
                  </div>
                ))
              ) : (
                <p>{t("NoContentAvailable")}</p>
              )}
            </div>
          ))
        ) : (
          <p>Não há seções disponíveis para este post.</p> // Mensagem se não houver seções
        )}
      </div>

      <div className={styles.shareButtons}>
        <h2>{t("Blog02")}</h2>
        <div className={styles.socialButtons}>
          <WhatsappShareButton url={window.location.href} title={post.title}>
            <FaWhatsapp size={32} />
          </WhatsappShareButton>
          <MailruShareButton url={window.location.href} subject={post.title}>
            <FaEnvelope size={32} />
          </MailruShareButton>
          <button
            onClick={() => navigator.clipboard.writeText(window.location.href)}
            className={styles.copyLinkButton}
          >
            <RiShareFill size={32} />
          </button>
        </div>
      </div>

      <div className={styles.backToBlog}>
        <button onClick={() => navigate("/blog")} className={styles.backButton}>
          {t("Blog01")}
        </button>
      </div>
    </div>
  );
}

export default PostBlog;
