import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { IoMdArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import header from "../assets/Laserlipolisis/LaserlipolisisText.webp";
import imgInicio from "../assets/Laserlipolisis/LaserlipolisisText1.webp";
import imgLiftingCard from "../assets/Laserlipolisis/LaseerLipolisisCard.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";
import AgendarCita from "../components/Seção_Agendar/AgendarCita";

//Components

function Laserlipolisis() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Laserlipolisis01")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Laserlipolisis02")}
            subtitle={t("Laserlipolisis03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Laserlipolisis04")}
            subtitle={t("Laserlipolisis05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Laserlipolisis06")}
            subtitle={t("Laserlipolisis07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Laserlipolisis08")}
            subtitle={t("Laserlipolisis09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro2} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Laserlipolisis10")}
            <p
              className={`${styles.subtitleIntro} ${
                animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
              }`}
              ref={ref}
            >
              {t("Laserlipolisis11")}
            </p>
          </h2>

          <p>{t("Laserlipolisis12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}>
            {" "}
            {t("LaserlipólisisCirculo")}{" "}
          </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Laserlipolisis13")}
              content={[t("Laserlipolisis14")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Laserlipolisis15")}
              content={[t("Laserlipolisis16")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Laserlipolisis17")}
              content={[t("Laserlipolisis18")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Laserlipolisis19")}
              content={[t("Laserlipolisis20")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div
            className={` ${styles.slideRight} `}
            id={styles.cardTratamiento}
            ref={ref}
          >
            <div className={styles.contentCard}>
              <p className={styles.titlePrincipal}>{t("Laserlipolisis40")}</p>
              <p className={styles.conteudo}>
                <span key="1" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Laserlipolisis41")}
                  </strong>{" "}
                  {t("Laserlipolisis42")}
                </span>
                <br />
                <span key="2" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Laserlipolisis43")}
                  </strong>{" "}
                  {t("Laserlipólisis44")}
                </span>
                <br />
                <span key="3" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Laserlipolisis45")}
                  </strong>{" "}
                  {t("Laserlipolisis46")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Laserlipolisis21")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("Laserlipolisis22")}
                    </p>
                    <p>{t("Laserlipolisis23")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {" "}
                      {t("Laserlipolisis24")}{" "}
                    </p>
                    <p>{t("Laserlipolisis25")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("Laserlipolisis26")}
                    </p>
                    <p>{t("Laserlipolisis27")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4> {t("Laserlipolisis28")} </h4>
        <p className={styles.subtitlePlus}>{t("Laserlipolisis29")}</p>
        <div className={styles.contentPlus} id={styles.plusAlternativo}>
          <div className={styles.circlePlus}>
            <a href="/HIFUCorporal"> {t("Laserlipolisis30")} </a>
          </div>
          <Button
            className={styles.button}
            id={styles.buttonPlus}
            href="/Servicios"
          >
            {t("Laserlipolisis31")}
          </Button>
        </div>
      </div>
      <AgendarCita />
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Laserlipolisis32")} </h2>
          <Perguntas
            pergunta={t("Laserlipolisis33")}
            resposta={t("Laserlipolisis34")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Laserlipolisis35")}
            resposta={t("Laserlipolisis36")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Laserlipolisis37")}
            resposta={t("Laserlipolisis38")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("Laserlipolisis39")}
            resposta={t("Laserlipolisis48")}
            eventKey="3"
          />
        </div>
      </div>
    </div>
  );
}

export default Laserlipolisis;
