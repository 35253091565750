import React from "react";
import "./Loading.css"; // Importando o CSS para o componente
import logoWhite from "../../assets/Logotipo-Moreira-blanc.webp";
// Em Loading.js
const Loading = ({ isLoading }) => {
  return (
    <div className={`loading-overlay ${!isLoading ? "out" : ""}`}>
      <div className="loading-content">
        <img
          src={logoWhite}
          className="logo-loading"
          alt="Logotipo carregando"
        />
        <h1>
          Cargando<span className="dot dot1">.</span>
          <span className="dot dot2">.</span>
          <span className="dot dot3">.</span>
        </h1>
      </div>
    </div>
  );
};

export default Loading;
