import { IoMdArrowDown } from "react-icons/io";
import { useState } from "react";
import styles from "./Acordeao.module.css";

function Acordeao({ title, content }) {
  const [isOpen, setIsOpen] = useState(false); // Controla se o acordeão está aberto ou fechado

  const toggle = () => {
    setIsOpen(!isOpen); // Alterna o estado de abertura
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.accordion}>
          <div className={styles.item}>
            <div className={styles.title} onClick={toggle}>
              <p>{title}</p>
              <span className={styles.arrow}>
                <IoMdArrowDown className={isOpen ? styles.rotateIcon : ""} />
              </span>
            </div>
            <div
              className={
                isOpen ? `${styles.content} ${styles.show}` : styles.content
              }
            >
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acordeao;
