// resources.js
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";

const resources = {
  es: {
    translation: es,
  },
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

export default resources;
