import { Button } from "react-bootstrap";
import "./Button.css";

function ButtonGreen({ content, type }) {
  return (
    <Button type={type} className="buttonGreen">
      {content}
    </Button>
  );
}

export default ButtonGreen;
