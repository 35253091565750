// Admin.js
import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostForm from "./Components/PostForm";
import AdminAuth from "./Components/AdminAuth";
import styles from "./Admin.module.css";

function Admin() {
  const [authenticated, setAuthenticated] = useState(false);
  const [posts, setPosts] = useState([]);
  const [editingPost, setEditingPost] = useState(null);
  const formRef = useRef(null);

  const fetchPosts = async () => {
    try {
      const response = await fetch(
        "https://api.dralexmoreiraesthetic.com/api/posts",
        {
          headers: {
            pin: localStorage.getItem("adminPin"),
          },
        }
      );
      if (!response.ok)
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Error al buscar publicaciones:", error);
      alert(
        "Error al buscar publicaciones. Verifique la consola para más detalles."
      );
    }
  };

  useEffect(() => {
    if (authenticated) {
      fetchPosts();
    }
  }, [authenticated]);

  const handlePostCreated = (newPost) => {
    if (editingPost) {
      setPosts(
        posts.map((post) => (post._id === newPost._id ? newPost : post))
      );
      setEditingPost(null);
    } else {
      setPosts([...posts, newPost]);
    }
    toast.success("¡Publicación creada con éxito!");
  };

  const handleDeletePost = async (id) => {
    try {
      const response = await fetch(
        `https://api.dralexmoreiraesthetic.com/api/posts/${id}`,
        {
          method: "DELETE",
          headers: {
            pin: localStorage.getItem("adminPin"),
          },
        }
      );
      if (!response.ok)
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      setPosts(posts.filter((post) => post._id !== id));
    } catch (error) {
      console.error("Error al eliminar la publicación:", error);
      alert(
        "Error al eliminar la publicación. Verifique la consola para más detalles."
      );
    }
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleFormReset = () => {
    setEditingPost(null);
  };

  return (
    <div>
      <ToastContainer />
      {authenticated ? (
        <>
          <h1 className={styles.title}>Panel de Administrador</h1>
          <div ref={formRef}>
            <PostForm
              onPostCreated={handlePostCreated}
              editingPost={editingPost}
              onFormReset={handleFormReset}
            />
          </div>
          <div className={styles.postsContainer}>
            <h1>Publicaciones Registradas</h1>
            {posts.length > 0 ? (
              posts.map((post) => (
                <div key={post._id} className={styles.postCard}>
                  <h3 className={styles.postTitle}>{post.title}</h3>
                  <div className={styles.postContent}>
                    {post.sections &&
                      post.sections.map((section, sIndex) => (
                        <div key={sIndex}>
                          <h4>{section.title}</h4>
                          {section.paragraphs &&
                            section.paragraphs.map((paragraph, pIndex) => (
                              <p key={pIndex}>{paragraph}</p>
                            ))}
                          {section.subsections &&
                            section.subsections.map((subsection, ssIndex) => (
                              <div key={ssIndex}>
                                <h5>{subsection.title}</h5>
                                {subsection.paragraphs &&
                                  subsection.paragraphs.map(
                                    (subParagraph, spIndex) => (
                                      <p key={spIndex}>{subParagraph}</p>
                                    )
                                  )}
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                  {post.photo && (
                    <img
                      src={`https://api.dralexmoreiraesthetic.com/${post.photo}`}
                      alt={post.title}
                      className={styles.photo}
                    />
                  )}
                  <div className={styles.buttonEditRem}>
                    <button
                      className={styles.buttonEdit}
                      onClick={() => handleEditPost(post)}
                    >
                      Editar
                    </button>
                    <button
                      className={styles.buttonRemove}
                      onClick={() => handleDeletePost(post._id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No se encontraron publicaciones.</p>
            )}
          </div>
        </>
      ) : (
        <AdminAuth onAuthSuccess={() => setAuthenticated(true)} />
      )}
    </div>
  );
}

export default Admin;
