import React from "react";
import styles from "../../pages/lifting.module.css";
const Icon = ({ src, title, subtitle, iconAdd }) => {
  return (
    <div className={styles.icon}>
      <img src={src} alt={title} />
      <p className={styles.titleIcon}>{title}</p>
      <p className={styles.subtitleIcon}>{subtitle}</p>
    </div>
  );
};

export default Icon;
