import React from "react";
import { useTranslation } from "react-i18next";
import imgAgenda from "../../assets/images_home/HomeAgendar2.webp";
import styles from "./AgendarCita.module.css";
import { Button } from "react-bootstrap";
function AgendarCita() {
  const { t } = useTranslation();
  return (
    <div className={styles.containerAgenda}>
      <div className={styles.cardAgenda}>
        <div className={styles.contentAgenda}>
          <h1 className={styles.titleAgenda}> {t("agendaLifting")} </h1>
          <h1 className={styles.subtitleAgenda}> {t("tuCitaAhoraLifting")} </h1>
        </div>
        <a href="https://wa.me/34651551473" target="_blank">
          <Button className={styles.buttonAgenda}>
            {t("pedircitaLifting")}
          </Button>
        </a>
      </div>
      <img src={imgAgenda} className={styles.imgAgenda} />
    </div>
  );
}

export default AgendarCita;
